import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import PageContent from "../components/PLP/PageContent"
import { graphql } from "gatsby"

const Shop = ({ location, data }) => {
  const path = location.pathname
  const { page, products, productContents } = data
  const plpProducts = []

  products.nodes.forEach((product) => {
    let content = productContents.nodes.find((contentData) => contentData.productSlug === product.slug)
    let productData = product;
    if (content) {
      productData = Object.assign(productData, content);
    }
    plpProducts.push(productData)
  })

  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <PageContent blocks={page.blocks} products={plpProducts} />
    </Layout>
  )
}

export default Shop

export const pageQuery = graphql`
  query ShopPageQuery {
    page: contentfulPage(slug: { eq: "shop" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          id
          header
          subText {
            raw
          }
          ctaLabel
          ctaLink
          hero {
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(width: 808)
          }
        }
      }
    }
    products: allShopifyProduct {
      nodes {
        product_id: shopifyId
        name: title
        description
        price: priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          price
          shopifyId
        }
        slug: handle
        images {
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
      }
    }
    productContents: allContentfulProductContent {
      nodes {
        productSlug
        productMedias {
          file {
            url
            contentType
          }
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
        hoverImage {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
      }
    }
  }
`
