import React from "react"
import styled from "styled-components"
import { ButtonStyleGrey } from "./Styles"
import PropTypes from "prop-types"

const ButtonContainer = styled.button`
  ${ButtonStyleGrey}
`;

const OnClickButtonGrey = ({onClick, children, transparent, disabled, type}) => {
  return (
    <ButtonContainer onClick={onClick} transparent={transparent ? 1 : 0} disabled={disabled ? 1 : 0} type={type}>
      {children}
    </ButtonContainer>
  )
};

OnClickButtonGrey.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default OnClickButtonGrey;
