import React from "react"
import Hero from "./Hero"
import ProductList from "./ProductList"

const PageContent = ({ blocks, products }) => {
  let pageContents = []

  let hero

  blocks.forEach((block, index) => {
    const type = block.__typename
    if (type === "ContentfulHeroBlock") {
      hero = block
    }
  })

  return (
    <React.Fragment>
      {hero && <Hero {...hero} />}
      <ProductList products={products}/>
      {pageContents}
    </React.Fragment>
  )
}

export default PageContent
