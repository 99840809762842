import React from "react"
import styled from "styled-components"
import { ButtonStyleOrangeDark } from "./Styles"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ButtonContainer = styled(Link)`
  ${ButtonStyleOrangeDark}
`;

const ButtonOrangeDark = ({to, children, transparent}) => {
  return (
    <ButtonContainer to={to} transparent={transparent ? 1 : 0}>
      {children}
    </ButtonContainer>
  )
};

ButtonOrangeDark.propTypes = {
  to: PropTypes.string.isRequired
};

export default ButtonOrangeDark;
