import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Star from "../../resources/img/ui/star.svg"
import breakpoints from "../../styles/breakpoints"
import { Link } from "gatsby"
import { getProductLink } from "../../services/product/link"
import { getLocalisedProductPrice } from "../../services/product/price"
import AddToBagButton from "../PDP/AddToBagButton"

const ProductContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: 323px;
    margin: auto;
  }
`

const ProductImageContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 41px;
`

const ProductImage = styled(GatsbyImage)`
  img {
    padding: 32px;
    width: 100%;
    height: 100%;
  }
`

const ProductVideo = styled.div`
  padding-bottom: 100%;
  position: relative;
  video {
    position: absolute;
    width: 150%;
    height: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const LifestyleVideo = styled.video`
  position: absolute;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.5s;
`

const LifestyleImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.5s;
  img {
    object-fit: ${(props) => props.objectFit}!important;
  }
`

const Information = styled.div``

const NameSection = styled.div`
  @media (min-width: ${breakpoints.sm}) {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 55% 45%;
  }
`

const ProductName = styled.h3`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 24px;
  line-height: 100%;

  @media (min-width: ${breakpoints.md}) {
    font-size: 30px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
  }
`

const Price = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 19px;
  letter-spacing: 3.8px;
`

const Reviews = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 150%;
  margin-top: 11px;
`

const PriceSection = styled.div`
  margin-top: 15px;
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 0;
    text-align: right;
  }
`

const Stars = styled.div`
  margin-right: 14px;
  display: inline-block;
  img {
    width: 15px;
    height: 15px;
    margin-right: 9px;
    :last-of-type {
      margin-right: 0;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    margin-right: 0;
    display: block;
  }
`

const ProductDescription = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 180%;
  margin-top: 23px;
`

const ButtonContainer = styled.div`
  margin-top: 48px;
  display: ${(props) => (props.mobile ? "block" : "none")};
  button {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    display: ${(props) => (props.desktop ? "block" : "none")};
    position: absolute;
    z-index: 2;
    bottom: 14px;
    width: 100%;
    padding: 0 15px;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: all 0.5s;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const ProductCard = (props) => {
  const [hover, setHover] = useState(false)
  const {
    name,
    slug,
    description,
    productMedias,
    hoverImage,
    variants
  } = props

  const product_id = variants[0].shopifyId;

  let rating = 5
  let stars = []

  for (let i = 0; i < rating; i++) {
    stars.push(<img src={Star} alt="Star" key={i} />)
  }

  let productImage
  let productVideo
  let lifestyleImage

  if (productMedias && productMedias.length > 0) {
    if (productMedias[0]) {
      if (productMedias[0].file.contentType.includes("video")) {
        productVideo = productMedias[0]
      } else {
        productImage = productMedias[0]
      }
    }
  }

  if (hoverImage) {
    lifestyleImage = hoverImage
  }

  return (
    <ProductContainer>
      <Link to={getProductLink(slug)}>
        {(productImage || productVideo) && (
          <ProductImageContainer
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {productVideo ? (
              <ProductVideo>
                <video
                  src={productVideo.file.url}
                  autoPlay
                  muted
                  loop
                  playsInline
                  preload="metadata"
                  aria-label="video"
                />
              </ProductVideo>
            ) : (
              <ProductImage image={productImage.gatsbyImageData} alt={name} />
            )}
            {lifestyleImage &&
              (lifestyleImage.file.contentType.includes("video") ? (
                <LifestyleVideo
                  src={lifestyleImage.file.url}
                  autoPlay
                  muted
                  loop
                  playsInline
                  preload="metadata"
                  aria-label="video"
                  visible={hover ? 1 : 0}
                />
              ) : (
                <LifestyleImage
                  image={lifestyleImage.gatsbyImageData}
                  alt={name}
                  visible={hover ? 1 : 0}
                  objectFit={
                    lifestyleImage.file.contentType === "image/png"
                      ? "contain"
                      : "cover"
                  }
                />
              ))}
            <ButtonContainer desktop visible={hover ? 1 : 0}>
              <AddToBagButton productId={product_id}>Quick Add</AddToBagButton>
            </ButtonContainer>
          </ProductImageContainer>
        )}
      </Link>
      <StyledLink to={getProductLink(slug)}>
        <Information>
          <NameSection>
            <ProductName>{name}</ProductName>
            <PriceSection>
              <Price>{getLocalisedProductPrice(props)}</Price>
              {/* <Reviews>
                <Stars>{stars}</Stars>
                (29 Reviews)
              </Reviews> */}
            </PriceSection>
          </NameSection>
          <ProductDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <ButtonContainer mobile>
            <AddToBagButton productId={product_id}>QuickAdd</AddToBagButton>
          </ButtonContainer>
        </Information>
      </StyledLink>
    </ProductContainer>
  )
}

export default ProductCard
