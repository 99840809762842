import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import ProductCard from "./ProductCard"

const PlpContainer = styled.div`
  margin-top: 47px;
  margin-bottom: 210px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 97px;
  }
`

const CollectionContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding: 0 47px;
  }
`

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 63px;

  }
`

const Title = styled.h2`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
`

const SortButton = styled.button`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  background: transparent;
  outline: none;
  padding: 4px 10px;
  border: none;
  font-size: 18px;
  line-height: 160%;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 10px;
    height: 10px;
    margin-left: 4px;
  }
`

const SortContainer = styled.div`
  position: absolute;
  padding: 56px 45px;
  top: 100%;
  right: 0;
  background: ${colors.peach};
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  z-index: 1;
  width: calc(100vw - 60px);
  max-width: 322px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 277px;
    padding: 56px 39px 64px;
  }
`

const Option = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 27px;
  cursor: pointer;
  :last-of-type {
    margin-bottom: 0;
  }
  :hover {
    text-decoration: underline;
  }
`

const ProductsRow = styled.div`
  display: grid;
  grid-gap: 56px;
  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 50px;
    grid-gap: 96px;
  }
`

const ProductList = (props) => {
  const { products } = props
  // const [sortOpen, setSortOpen] = useState(false)

  // const sortOptions = [
  //   { name: "Best Seller", value: "best-seller" },
  //   { name: "New to Old", value: "new-to-old" },
  //   { name: "Low to High", value: "low-to-high" },
  //   { name: "High to Low", value: "high-to-low" },
  // ]

  return (
    <PlpContainer>
      <ContentContainer>
        <CollectionContainer>
          <TitleSection>
            <Title>Shop all</Title>
            {/* <SortButton onClick={() => setSortOpen(!sortOpen)}>
              Sort
              {sortOpen ? (
                <img src={Line} alt="Close sort" />
              ) : (
                <img src={Plus} alt="Open sort" />
              )}
            </SortButton>
            {sortOpen && (
              <SortContainer>
                {sortOptions.map((option, index) => {
                  const { name, value } = option
                  return <Option key={index}>{name}</Option>
                })}
              </SortContainer>
            )} */}
          </TitleSection>
          <ProductsRow>
            {products.map((product, index) => {
              return <ProductCard {...product} key={index} />
            })}
          </ProductsRow>
        </CollectionContainer>
      </ContentContainer>
    </PlpContainer>
  )
}

export default ProductList
