import React, { useContext, useState } from "react"
import { CartContext } from "../../context/CartContext"
import { StateContext } from "../../context/StateContext"
import { addItem, getCart } from "../../services/cart/cart"
import OnClickButtonGrey from "../Core/Buttons/OnClickButtonGrey"

const AddToBagButton = (props) => {
  const { productId, children, quantity, sellingPlanId } = props
  const [isAdding, setIsAdding] = useState(false);
  const [cart, setCart] = useContext(CartContext);
  const [state, setState] = useContext(StateContext);

  const add = async (e) => {
    e.preventDefault()

    setIsAdding(true);

    let productQuantity = quantity;

    if (!quantity) {
      productQuantity = 1
    }

    await addItem(productId, productQuantity, sellingPlanId)

    const cartData = await getCart()
    
    setCart(cartData);
    
    setState({isBasketOpen : !state.isBasketOpen})
    
    setIsAdding(false);

  }

  return (
    <OnClickButtonGrey onClick={(e) => add(e)}>
      {isAdding ? "Adding..." : children}
    </OnClickButtonGrey>
  )
}

export default AddToBagButton
