import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ButtonOrangeDark from "../Core/Buttons/ButtonOrangeDark"
import { Col, Row } from "styled-bootstrap-grid"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const HeroContainer = styled.div`
  margin: auto;
  margin-bottom: 59px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 74px;
  }
`

const StyledRow = styled(Row)`
  margin: auto;
  position: relative;
`

const HeroImage = styled(GatsbyImage)`
  height: 404px;

  @media (min-width: ${breakpoints.md}) {
    height: 443px;
  }
`

const MobileImage = styled(HeroImage)`
  display: block;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const DesktopImage = styled(HeroImage)`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const HeroContentContainer = styled(Col)`
  padding: 0 28px;
  max-width: ${breakpoints.xxl};

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    padding: 0 42px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: ${breakpoints.max};
  }
`

const HeroContent = styled.div`
  padding: 54px 0 50px;
  @media (min-width: ${breakpoints.md}) {
    padding: 25px 0 0;
  }
`

const Header = styled.h1`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    color: ${colors.white};
    font-size: 70px;
    max-width: 496px;
    text-align: left;
  }
`

const ButtonContainer = styled.div`
  margin-top: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    min-width: 317px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 23px;
    a {
      min-width: 215px;
    }
  }
`

const SubHeader = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 180%;
  color: ${colors.grey};
  text-align: center;
  margin-top: 14px;

  @media (min-width: ${breakpoints.md}) {
    color: ${colors.white};
    max-width: 347px;
    text-align: left;
  }
`

const Hero = (props) => {
  const { hero, mobileHero, header, ctaLabel, ctaLink, subText } = props

  return (
    <HeroContainer>
      <StyledRow>
        <Col order="last">
          <MobileImage image={mobileHero? mobileHero.gatsbyImageData : hero.gatsbyImageData} alt={header} />
          <DesktopImage image={hero.gatsbyImageData} alt={header} />
        </Col>
        <HeroContentContainer>
          <HeroContent>
            <Header>{header}</Header>
            {subText && <SubHeader>{renderRichText(subText)}</SubHeader>}
            {ctaLink && ctaLabel && (
              <ButtonContainer>
                <ButtonOrangeDark to={ctaLink}>{ctaLabel}</ButtonOrangeDark>
              </ButtonContainer>
            )}
          </HeroContent>
        </HeroContentContainer>
      </StyledRow>
    </HeroContainer>
  )
}

export default Hero
