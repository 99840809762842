import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import BlockContainer from "./BlockContainer"

const StyledContainer = styled.div`
  padding: 0 28px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 42px;
  }
`

const ContentContainer = ({children}) => {
  return (
    <BlockContainer>
      <StyledContainer>
        {children}
      </StyledContainer>
    </BlockContainer>
  )
}

export default ContentContainer

